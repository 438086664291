import { keys } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closePopup } from '../actions/popup';
import { BASE_ZINDEX } from '../popup-factory';
import { createAddItem, createAddItemCancel, createAddSuggestionItem } from '../actions';
import { createFetchProductDetails } from '../actions/product';
import SelectSku from './SelectSku';
import { Button, ErrorBoundary, Popup, Theme, themeOptions } from '@commonsku/styles';
import { createGlobalStyle } from './helpers';

const CURRENCY_MAP = {
  'CA': 'CAD'
};

const GlobalStyle = createGlobalStyle();

class SelectFobPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      skus: []
    };

    ['onSkusSelected'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  onSkusSelected(skus) {
    this.setState({ skus });
  }

  render() {
    const { loading, loaded, parent_type, product, onAddProduct, onClosePopup, index } = this.props;
    const fobs = product.fobs || [];
    const fob = this.props.currency_id ? (fobs.filter(f => f.supported_currencies.includes(this.props.currency_id))[0] || fobs[0] || {}) : (product.fobs[0] || {});
    const currencies = keys(product.indexed_costs);
    const currency_id = currencies.includes(this.props.currency_id) ? this.props.currency_id : (
      currencies.includes(CURRENCY_MAP[fob.country]) ? CURRENCY_MAP[fob.country] :
      product.currency_id || (currencies.includes('USD') ? 'USD' : (currencies[0] || 'USD'))
    );
    const fob_id = fob.fob_id;

    const intro = 'OPTION' === parent_type ?
      'Please select the default SKU for pricing' :
      'Please select SKUs';
    return (
      <Theme theme={themeOptions}>
        <GlobalStyle theme={themeOptions} />
        <ErrorBoundary>
          <Popup
            title={`Add ${product.product_name}`}
            onClose={this.handleClose}
            zIndex={BASE_ZINDEX + index}
            overlayZIndex={BASE_ZINDEX + index}
            controls={<>
              <Button style={{ marginRight: 10, }} variant={'secondary'} onClick={e => onClosePopup()}>Cancel</Button>
              <Button
                variant={!this.state.skus[0] ? 'disabled' :'cta'}
                disabled={!this.state.skus[0]}
                onClick={() => onAddProduct(fob_id, currency_id, this.state.skus)}
              >Add Product</Button>
            </>}
          >
            <div style={{ paddingTop: 15, }}>
              <SelectSku
                product={product}
                product_id={product.product_id}
                currency_id={currency_id}
                onSkusSelected={this.onSkusSelected}
                multi={'PRODUCT' === parent_type}
                maxHeight="24rem"
                view_type="OptionsByColor"
                message={intro}
              />
            </div>
          </Popup>
        </ErrorBoundary>
      </Theme>
    );
  }
}

const mapStateToProps = state => {
  const loading = state.display.loading.add_item;
  const loaded = state.display.loading.added_item;
  return {
    loading,
    loaded
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const suggestion_id = ownProps.suggestion_id ? ownProps.suggestion_id : null;
  const event = ownProps.event ? ownProps.event : null;
  return {
    onAddProduct: (fob_id, currency_id, skus = null) => {
      let result;
      if ('MESSAGE' === ownProps.order_id) {
        result = dispatch(createFetchProductDetails(ownProps.product.product_id, ownProps.product_source, 'MESSAGE'));
      } else if (ownProps.suggestion_id) {
        result = dispatch(createAddSuggestionItem(ownProps.order_id, ownProps.parent_type, ownProps.product.ext_product_id, ownProps.product_source, event ? 'collaborate' : 'message-suggest', suggestion_id, event, fob_id, currency_id, skus));
      } else {
        result = dispatch(createAddItem(ownProps.order_id, ownProps.parent_type, ownProps.product.ext_product_id, ownProps.product_source, ownProps.target_type, ownProps.display_order, fob_id, currency_id, skus));
      }
      dispatch(closePopup());
      if (ownProps.success_callback) {
        result.then(ownProps.success_callback);
      }
    },
    onClosePopup: () => {
      dispatch(closePopup());
      dispatch(createAddItemCancel());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectFobPopup);
